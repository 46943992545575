<template>
  <v-menu bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        :text="flat"
        :icon="icon"
        :color="type"
        rounded
        v-on="on"
        :disabled="disabled"
        class="formButton"
        :loading="loading"
      >
        <slot>{{ text }}</slot>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(opt, index) in options"
        :key="'list-item-' + randomId + '-' + index"
        @click="onClick(opt)"
        :disabled="Object.keys(opt).includes('disable') ? opt.disable : false"
      >
        <v-list-item-title>
          <slot :name="opt.value" :item="opt">
            {{ opt.text }}
          </slot>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FormButtonMenu',
  props: {
    text: {
      type: String,
      default: '',
      required: false
    },
    flat: {
      type: Boolean,
      default: false,
      required: false
    },
    icon: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: '',
      required: false
    },
    options: {
      type: Array,
      default: () => [],
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    randomId: Math.random() * 1000
  }),
  methods: {
    onClick(option) {
      this.$emit('click', option)
    }
  }
}
</script>
