<template>
  <SidePopup
    :title="titulo"
    v-on:close="onClose"
    @submit="importar"
    :tabs="tabs"
    :tabStart="tabStart"
    overlay-not-back
  >
    <template v-slot:form>
      <FormField v-if="filtro" :label="labelFiltro" v-model="form.filtro" />
      <FormButtonGroup align="end" spaced>
        <FormButton
          text="Baixar planilha de exemplo"
          type="secundario"
          :loading="inDownload"
          @click="exportar"
        />
      </FormButtonGroup>
      <FormFile
        label="Selecione a planilha"
        v-model="form.arquivo"
        :validation="$v.form.arquivo"
        @file="onSelecionaPlanilha"
      >
      </FormFile>
      <FormSelect
        label="Separador das colunas da planilha"
        v-model="form.separador"
        :options="opcoesDelimitador"
        :validation="$v.form.separador"
      />
      <LineDivider>
        <h4>Descrição da planilha</h4>
      </LineDivider>
      <DataTable
        :headers="headers_config"
        :data="tabela_configuracao"
        :itemsPerPage="30"
        sortBy="coluna"
        :sortDesc="false"
        ref="datatable_config"
        :auto-search="false"
      />
    </template>
    <template v-slot:info>
      <DataTable
        :headers="headers_processo"
        :endpoint="endpoint_processo"
        sortBy="data_upload"
        :sortDesc="true"
        ref="datatable_processo"
      >
        <template v-slot:data_upload="{ col }">
          {{ col | formatDateTime(getTimeZone) }}
        </template>
        <template v-slot:status="{ col }">
          {{ statusLabel[col] }}
        </template>
        <template v-slot:data_processamento="{ col }">
          <template v-if="col">{{
            col | formatDateTime(getTimeZone)
          }}</template>
        </template>
        <template v-slot:output_file="{ col }">
          <FormButton
            text="Relatório"
            type="secundario"
            @click="() => donwloadRelatorio(col.file_url)"
            v-if="col && col.file_url"
          />
        </template>
      </DataTable>
    </template>
    <template v-slot:buttons>
      <FormButton text="Importar" submit type="submissao" :loading="inUpload" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import DataTable from '@/components/DataTable'
import FormFile from '@/components/form/FormFile'
import LineDivider from '@/components/LineDivider'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import {
  EDICAO_PLANILHA_IMPORTAR,
  EDICAO_PLANILHA_EXPORTAR,
  EDICAO_PLANILHA_CONFIG
} from '@/store/actions/edicao-planilha'
import * as _ from 'lodash'

export default {
  name: 'EdicaoEmPlanilha',
  components: {
    SidePopup,
    DataTable,
    FormButtonGroup,
    LineDivider,
    FormButton,
    FormSelect,
    FormField,
    FormFile
  },
  props: {
    modelo: {
      type: String,
      required: true
    },
    filtro: {
      type: String
    }
  },
  data: () => ({
    form: {
      planilha: null,
      arquivo: '',
      separador: ';',
      filtro: ''
    },
    opcoesDelimitador: [
      { text: 'Ponto e virgula (";")', value: ';' },
      { text: 'Virgula (",")', value: ',' }
    ],
    titulo_map: {
      planilha_produto: 'Planilha de produtos',
      planilha_vinculo_produto_politica:
        'Planilha de vinculo produtos e politica de desconto',
      planilha_vinculo_cliente_politica:
        'Planilha de vinculo clientes e politica de desconto',
      planilha_politica: 'Planilha de politicas de desconto',
      planilha_vinculo_categoria: 'Planilha vinculo categoria e produto',
      planilha_vinculo_tag: 'Planilha vinculo tags e produto',
      planilha_tag_pesquisa: 'Planilha de tags de produtos',
      planilha_agrupador_tag: 'Planilha de agrupador de tags de produtos',
      planilha_vinculo_atendente_padrao: 'Planilha vinculo atendente padrão',
      planilha_conjunto: 'Planilha de conjuntos',
      planilha_vinculo_produto_conjunto:
        'Planilha de vínculo de produtos ao conjunto'
    },
    tabs: [
      {
        key: 'form',
        text: 'Importação'
      },
      {
        key: 'info',
        text: 'Processos'
      }
    ],
    headers_config: [
      { text: 'Coluna', value: 'coluna' },
      { text: 'Descricao', value: 'descricao' },
      { text: 'Valor válido', value: 'valor_valido' },
      { text: 'Valor Único', value: 'unica' },
      { text: 'Observação', value: 'observacao' }
    ],
    headers_processo: [
      { text: 'Data importação', value: 'data_upload' },
      { text: 'Status', value: 'status' },
      { text: 'Descrição', value: 'descricao_status' },
      { text: 'Data finalização', value: 'data_processamento' },
      { text: 'Relatório', value: 'output_file' }
    ],
    statusLabel: {
      PENDENTE: 'Pendente',
      CONCLUIDO: 'Concluído',
      CONCLUIDO_COM_ERROS: 'Concluído com erros',
      FALHA: 'Falha',
      TIMEOUT: 'Falha na importação'
    },
    tabela_configuracao: [],
    inDownload: false,
    inUpload: false,
    tabStart: 0
  }),
  validations: {
    form: {
      arquivo: { required },
      separador: { required }
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'getTimeZone']),
    titulo() {
      return this.titulo_map[this.modelo]
    },
    endpoint_processo() {
      return `/api/v4/edicao-planilha/datatable/${this.getFornecedorId}/${this.modelo}`
    },
    labelFiltro() {
      return `Filtrar por ${this.filtro}`
    }
  },
  watch: {
    modelo: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          this.configuracao()
        }
      }
    }
  },
  created() {
    this.configuracao()
  },
  methods: {
    configuracao() {
      this.$store.dispatch(EDICAO_PLANILHA_CONFIG, this.modelo).then(resp => {
        const tmp = _.get(resp, 'data', []) || []
        this.tabela_configuracao = tmp.map(t => {
          return {
            ...t,
            unica: t.unica ? 'Sim' : 'Não'
          }
        })
      })
    },
    importar() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.inUpload = true
      this.$store
        .dispatch(EDICAO_PLANILHA_IMPORTAR, {
          ...this.form,
          modelo: this.modelo
        })
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Planilha importada, em processamento'
          )
          this.tabStart = 1
          this.$refs.datatable_processo.reload()
          this.inUpload = false
        })
        .catch(() => {
          this.inUpload = false
        })
    },
    exportar() {
      this.inDownload = true
      this.$store.dispatch(EDICAO_PLANILHA_EXPORTAR, this.modelo).then(resp => {
        var blob = new Blob([resp], { type: 'text/csv' })
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveOrOpenBlob(blob, `${this.modelo}.csv`)
          this.inDownload = false
        } else {
          var a = window.document.createElement('a')
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = `${this.modelo}.csv`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          this.inDownload = false
        }
      })
    },
    donwloadRelatorio(linkRelatorio) {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = linkRelatorio
      a.download = `relatorio_processamento.csv`
      document.body.appendChild(a)
      a.click()
    },
    onClose() {
      this.$emit('close')
    },
    onSelecionaPlanilha(file) {
      this.form.planilha = file
    }
  }
}
</script>

<style lang="scss">
.seletor {
  margin: 0px !important;
  .v-input__slot {
    margin: 0px !important;
  }
  .v-messages {
    display: none !important;
  }
}
</style>
