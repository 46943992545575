<template>
  <v-chip class="ma-2" label :small="small" :color="color" text-color="white">
    <v-icon left v-if="icon">{{ icon }}</v-icon>
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    text: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false,
      required: false
    },
    color: {
      type: String,
      default: 'white'
    },
    icon: String
  }
}
</script>
