<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButtonMenu
            type="secundario"
            :options="planilhaOptions"
            @click="op => selectOperacao(op)"
          >
            Operação em planilha
          </FormButtonMenu>
          <FormButton text="Novo conjunto" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :filters="filters"
        sortBy="nome"
        :sortDesc="false"
        @click="onClick"
        ref="datatable"
        search
        noHideFilters
        :auto-search="false"
      >
        <template v-slot:disponivel="{ col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-else color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:tem_imagem="{ col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-else color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:idproduto="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.idcadastroextraproduto]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <EdicaoEmPlanilha
        v-if="modalEdicaoEmPlanilha.open"
        :modelo="modalEdicaoEmPlanilha.modelo"
        @close="modalEdicaoEmPlanilha.open = false"
      />
    </transition>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { mapGetters } from 'vuex'
import { PRODUTO_REMOVE } from '@/store/actions/produto'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import Chip from '@/components/Chip'
import EdicaoEmPlanilha from '@/components/EdicaoEmPlanilha'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { SETOR_FOLHA_LOAD } from '@/store/actions/setor'
import { SEGMENTO_LIST } from '@/store/actions/segmento'

export default {
  name: 'ConjuntoTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    Chip,
    FormButtonGroup,
    EdicaoEmPlanilha,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Nome do conjunto', value: 'nome' },
      {
        text: 'Código interno',
        value: 'id_produto_erp',
        align: 'center'
      },
      {
        text: 'Código de barras',
        value: 'codigobarras',
        align: 'center'
      },
      {
        text: 'Disponível para venda',
        value: 'disponivel',
        align: 'center'
      },
      {
        text: 'Tem imagem',
        value: 'tem_imagem',
        align: 'center'
      },
      {
        text: 'Opções',
        value: 'idproduto',
        clickable: false
      }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    planilhaOptions: [
      { text: 'Editar conjuntos', value: 'planilha_conjunto' },
      {
        text: 'Editar vínculo produtos ao conjunto',
        value: 'planilha_vinculo_produto_conjunto'
      }
    ],
    modalEdicaoEmPlanilha: {
      open: false,
      modelo: 'planilha_conjunto'
    },
    modalAssociar: false,
    modalProdutoSimilar: false,
    modalAssociarTags: false,
    loadingAcao: false,
    loadingRow: {},
    modulosForm: [
      ModulesEnum.PORTFOLIO_WEB,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'moduloFornecedorAtivo',
      'getFornecedorTag'
    ]),
    filters() {
      return [
        { label: 'Código interno', query: 'codigointerno' },
        { label: 'Código de barras', query: 'codigobarras' },
        {
          label: 'Disponibilidade',
          query: 'disponibilidade',
          default: '',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Disponível', value: 'true' },
            { text: 'Indisponível', value: 'false' }
          ]
        },
        {
          label: 'Vinculo de imagem',
          query: 'temimagem',
          options: [
            { text: 'Todos', value: '' },
            { text: 'Com imagem', value: 'true' },
            { text: 'Sem imagem', value: 'false' }
          ]
        }
      ]
    },
    endpoint() {
      return `/api/v1/produtos/datableformat?idfornecedor=${this.getFornecedorId}&tipoProduto=CONJUNTO&filtroSimples=`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  watch: {
    getFornecedorId: {
      handler() {
        this.loadData()
      }
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch(SEGMENTO_LIST)
      this.$store.dispatch(SETOR_FOLHA_LOAD)
      this.loadingRow = {}
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    onClick(item) {
      this.$router.push(
        `conjunto-produtos/${item.idproduto}/${item.idcadastroextraproduto}`
      )
    },
    onNew() {
      this.$router.push('conjunto-produtos/novo')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    selectOperacao(option) {
      const edicaoPlanilha = option.value.startsWith('planilha')
      if (edicaoPlanilha) {
        this.modalEdicaoEmPlanilha = {
          open: true,
          modelo: option.value
        }
        return
      }
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão do produto '${item.nome}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idcadastroextraproduto]: true
      }
      this.$store
        .dispatch(PRODUTO_REMOVE, item.idcadastroextraproduto)
        .then(() => {
          this.$vueOnToast.pop('success', 'Produto removido com sucesso')
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
        })
        .catch(() => {
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
        })
    }
  },
  created() {
    this.loadData()
  }
}
</script>
