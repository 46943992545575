<template>
  <v-col cols="12" :class="classComponent">
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  name: 'FormButtonGroup',
  props: {
    align: {
      type: String,
      default: 'start',
      required: false
    },
    spaced: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    classComponent() {
      let _class = ['d-flex', `justify-${this.align}`]
      if (this.spaced) {
        _class.push('space-items')
      }
      return _class
    }
  }
}
</script>

<style lang="scss">
.justify-start,
.justify-end,
.justify-startjustify-start {
  flex-wrap: wrap;
}

.justify-start.space-items > .formButton,
.justify-end.space-items > .formButton,
.justify-center.space-items > .formButton {
  margin: 2.5px 0;
}

.justify-start.space-items > *:not(:last-child) {
  margin-right: 10px;
}

.justify-end.space-items > *:not(:first-child) {
  margin-left: 10px;
}

.justify-center.space-items > * {
  margin: 0 5px;
}
</style>
