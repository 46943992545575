<template>
  <v-file-input
    :placeholder="placeholder || ''"
    :error-messages="errors"
    :disabled="disabled"
    :readonly="readonly"
    :dark="darkEnabled"
    :dense="dense"
    :label="label"
    :solo="solo"
    :clearable="clearable"
    outlined
    rounded
    flat
    :hint="hint"
    @change="onChangeFile"
    @blur="onBlur"
    ><template v-slot:append>
      <slot name="help">
        <v-tooltip top v-if="help">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">far fa-question-circle</v-icon>
          </template>
          {{ help }}
        </v-tooltip>
      </slot>
    </template>
    <template v-slot:selection>
      {{ model }}
    </template>
  </v-file-input>
</template>

<script>
import FormMixin from './FormMixin'
export default {
  name: 'FormFile',
  props: {
    value: {
      type: String,
      default: '',
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    help: {
      type: String,
      default: '',
      required: false
    }
  },
  mixins: [FormMixin],
  data: () => ({
    visibled: false,
    model: '',
    touched: false,
    formKey: Math.random() * 10000
  }),
  created() {
    this.model = this.value
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.model) {
          this.model = newVal
        }
      }
    }
  },
  methods: {
    onChangeFile(file) {
      this.model = file ? file.name : ''
      this.onChange()
      this.$emit('file', file)
    }
  }
}
</script>
