<template>
  <div
    class="container-popup-side"
    @keydown.esc="onKeyEsc"
    :class="{ dark: darkEnabled }"
  >
    <div class="backpoup" @click="onOutsideClick"></div>
    <div class="section-container">
      <div class="headerPopUp elevation-2">
        <v-btn
          icon
          :dark="darkEnabled"
          class="ClosePopUp"
          v-if="!hideBackButton"
          @click="overlayClose"
        >
          <v-icon v-if="routeback" size="20">fas fa-times</v-icon>
          <v-icon v-else size="20">fas fa-arrow-left</v-icon>
        </v-btn>
        <div
          class="header-title"
          :style="{ 'margin-left': !hideBackButton ? '30px' : '' }"
        >
          <slot name="title"
            ><h3>{{ title }}</h3></slot
          >
        </div>
      </div>
      <form @submit.prevent="onSubmit" ref="popup">
        <v-progress-linear
          class="loading-progress"
          indeterminate
          color="primario"
          v-if="loading"
          height="10"
        ></v-progress-linear>
        <div class="poupupbody">
          <slot></slot>
          <template v-if="tabs && tabs.length > 0">
            <v-tabs v-model="modelTab" @change="onChange">
              <v-tabs-slider></v-tabs-slider>
              <v-tab v-for="item in tabs" :key="item.key">
                {{ item.text }}
              </v-tab>
            </v-tabs>
            <div class="sidepopup-tab-content">
              <v-tabs-items v-model="modelTab">
                <v-tab-item v-for="item in tabs" :key="item.key">
                  <slot :name="item.key"></slot>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </template>
          <div class="footer-buttons">
            <slot name="cancel" :close="overlayClose" v-if="!hideCancel">
              <FormButton
                :text="cancelText"
                type="perigo"
                @click="overlayClose"
              />
            </slot>
            <slot name="buttons"></slot>
          </div>
          <div class="loading" v-if="loading">
            <div class="loading-text">
              Processando. Por favor, aguarde.
              <br />
              <v-icon>fas fa-circle-notch fa-spin</v-icon>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FormButton from '@/components/form/FormButton'
import { MENU_POPUP } from '@/store/actions/menu'
import { mapGetters } from 'vuex'
export default {
  name: 'SidePopup',
  components: { FormButton },
  props: {
    title: {
      type: String,
      required: false
    },
    hideBackButton: {
      type: Boolean,
      default: false,
      required: false
    },
    overlayNotBack: {
      type: Boolean,
      default: false,
      required: false
    },
    routeback: {
      type: String,
      default: '',
      required: false
    },
    hideCancel: {
      type: Boolean,
      default: false,
      required: false
    },
    cancelText: {
      type: String,
      default: 'Cancelar',
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    tabs: {
      type: Array,
      default: () => [],
      required: false
    },
    tabStart: {
      type: Number,
      default: 0,
      required: false
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: () => ({
    popUpKey: Math.random() * 1000,
    modelTab: 0
  }),
  computed: {
    ...mapGetters(['darkEnabled'])
  },
  watch: {
    tabStart: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.modelTab = newVal
        }
      }
    }
  },
  methods: {
    closePopUp() {
      if (this.routeback) {
        this.$router.replace({
          path: this.routeback,
          query: this.$route.query
        })
      } else this.$router.back()
    },
    onOutsideClick() {
      if (this.closeOnOutsideClick) {
        this.overlayClose()
      }
    },
    overlayClose() {
      if (!this.overlayNotBack) {
        this.closePopUp()
      }
      this.$emit('close')
    },
    onKeyUp(e) {
      if (e.keyCode == 27) {
        this.overlayClose()
      }
    },
    onSubmit() {
      if (!this.loading) {
        this.$emit('submit')
      }
    },
    onChange() {
      this.$emit('change-tab', this.modelTab)
    }
  },
  mounted() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.$store.dispatch(MENU_POPUP, {
      open: true,
      height: this.$refs.popup.clientHeight + 66
    })
    this.modelTab = this.tabStart
  },
  destroyed() {
    this.$store.dispatch(MENU_POPUP, { open: true })
  }
}
</script>

<style lang="scss" scoped>
.container-popup-side {
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  margin-right: 0px;
  padding: 0px;
  z-index: 900;
  &.dark {
    .section-container,
    .headerPopUp {
      background-color: #424242;
    }
    .headerPopUp {
      border-color: #fff;
      color: #fff;
    }
    .ClosePopUp {
      color: #fff;
    }
  }

  .backpoup {
    position: absolute;
    height: 100%;
    width: 200vw;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
    animation: enterAnime 1s ease-in-out;
  }
  .section-container {
    width: 80%;
    max-width: 1200px;
    min-height: 100%;
    background-color: #fff;
    z-index: 105;
    position: relative;
  }
  .headerPopUp {
    width: 100%;
    height: 52px;
    padding: 15px 20px;
    color: var(--primary-color);
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid var(--primary-color);
    position: fixed;
    top: 0;
    z-index: 920;
  }
  .ClosePopUp {
    color: var(--primary-color);
  }
  .poupupbody {
    width: 100%;
    padding: 80px 30px 80px 30px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 910;
    text-align: left;
  }
  .footer-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    flex-wrap: wrap;
    a,
    button {
      margin-left: 5px;
    }
  }
  .sidepopup-tab-content {
    padding-top: 50px;
  }
}
@media (max-width: 920px) {
  .section-container {
    width: 100%;
  }
}
@keyframes enterAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loading-progress {
  margin-top: 52px;
}
.loading {
  position: absolute;
  padding-top: 40%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 200;
  .loading-text {
    background: white;
    text-align: center;
    border: 1px solid #dfdfdf;
    padding: 10px;
    border-radius: 5px;
  }
}
</style>
