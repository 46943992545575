<template>
  <div class="data-table">
    <div class="data-table-filter">
      <v-row>
        <v-col xs="12" sm="12" md="4" lg="3" xl="2" v-if="search">
          <FormField
            :label="searchLabel"
            dense
            clearable
            v-model="searchValue"
            @change="searchChange"
          />
        </v-col>
        <v-col
          :xs="item.cols || '12'"
          :sm="item.cols || '12'"
          :md="item.cols || '4'"
          :lg="item.cols || '3'"
          :xl="item.cols || '2'"
          v-for="(item, index) in getFilters"
          :key="'filter-' + index"
        >
          <slot :name="'filter.' + item.query" :value="item.value">
            <FormSelect
              v-if="item.type == 'select'"
              :label="item.label"
              :placeholder="item.placeholder"
              v-model="item.value"
              dense
              :options="item.options"
              @change="searchChange"
              :clearable="item.clearable"
            />
            <FormDatePicker
              v-if="item.type == 'date'"
              dense
              v-model="item.value"
              :label="item.label"
              :placeholder="item.placeholder"
              @change="searchChange"
              :clearable="item.clearable"
            />
            <FormField
              v-if="item.type == 'text'"
              :label="item.label"
              :placeholder="item.placeholder"
              v-model="item.value"
              dense
              :clearable="item.clearable"
              @change="searchChange"
            />
            <FormField
              v-if="item.type == 'number'"
              :label="item.label"
              :placeholder="item.placeholder"
              v-model="item.value"
              typeField="number"
              dense
              :clearable="item.clearable"
              @change="searchChange"
            />
            <FormAutocomplete
              v-if="item.type == 'autocomplete'"
              :label="item.label"
              :placeholder="item.placeholder"
              v-model="item.value"
              dense
              :clearable="item.clearable"
              :options="item.options"
              @search="search => onSearchFilter(search, item)"
              @change="searchChange"
            />
          </slot>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col
          xs="12"
          sm="12"
          md="4"
          lg="3"
          xl="2"
          v-if="hasAdvancedFilter && !noHideFilters"
        >
          <FormButton type="auxiliar" full @click="toggleAdvancedFilter">
            {{ mostraFiltroAvancado ? 'Filtro simples' : 'Filtro avançado' }}
          </FormButton>
        </v-col>
        <v-col
          xs="12"
          sm="12"
          md="4"
          lg="3"
          xl="2"
          v-if="mostraFiltroAvancado || noHideFilters"
        >
          <FormButton
            text="Buscar"
            type="auxiliar"
            afterIcon="fas fa-search"
            full
            @click="searchClick"
          />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="pdf || csv || excel">
        <v-col cols="auto" class="mr-1" v-if="pdf">
          <FormButton text="PDF" @click="exportPDF" />
        </v-col>
        <v-col cols="auto" class="mr-1" v-if="csv">
          <FormButton text="CSV" @click="exportCSV" />
        </v-col>
        <v-col cols="auto" v-if="excel">
          <FormButton text="Excel" @click="exportExcel" />
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :headers="headers"
      :items="rows"
      :hide-default-footer="true"
      :show-select="showSelect"
      :single-select="singleSelect"
      :class="classe ? classe : 'elevation-1'"
      no-data-text="Nenhum registro encontrado"
      no-results-text="Nenhum registro encontrado"
      :options="tableOptions"
      :dark="darkEnabled"
      @update:sort-by="onSortBy"
      @update:sort-desc="onSortDesc"
      :custom-sort="customSort"
      :loading="loading"
      loading-text="Carregando dados..."
    >
      <template v-slot:header.data-table-select>
        <v-checkbox
          :value="allIsSelected"
          :indeterminate="partialIsSelected"
          @change="onSelectAll"
          :disabled="loading"
        ></v-checkbox>
      </template>
      <template v-slot:item="{ item }">
        <tr :class="{ 'line-disabled': loading }">
          <td v-if="showSelect">
            <v-checkbox
              :value="item"
              v-model="selected"
              @change="onSelect"
              :disabled="loading || item.disabled"
            ></v-checkbox>
          </td>
          <td
            v-for="col in headers"
            :key="col.value"
            :style="{ 'text-align': col.align || 'left' }"
            :class="{ clickable: col.clickable == false ? false : true }"
            @click="onClickRow(col, item)"
          >
            <slot
              :name="col.value"
              :row="item"
              :col="validateValue(item[col.value])"
            >
              <FormField
                :placeholder="col.text"
                v-model="valuesInput[item.$internal][col.value]"
                dense
                class="form-editable"
                v-if="col.editable"
                :disabled="valuesInput[item.$internal].disabled"
                :money="col.mask == 'money' ? moneyMask : null"
              />
              <span v-else>{{ item[col.value] || '' }}</span>
            </slot>
          </td>
        </tr>
      </template>
      <template
        v-slot:footer="{
          on,
          props: { pagination: { pageStart, pageStop } }
        }"
      >
        <div class="custom-footer" v-if="!hideFooter">
          <div class="perpage-selector">
            <span>Itens por página:</span>
            <v-menu offset-y v-model="selectorPageOpen">
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" :class="{ open: selectorPageOpen }">
                  <span style="margin-right:10px;">{{
                    options.itemsPerPage
                  }}</span>
                  <v-icon class="icon-selector">fas fa-caret-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="n in limitPage"
                  :key="n"
                  @click="selectPerPage(n)"
                >
                  {{ n }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="paga-index">
            Exibindo {{ calculePageStart(pageStart) }} a
            {{ pageStop + (options.start - 1 || 0) }} de
            {{ options.itemsLength }} registros
          </div>
          <div class="pagination-controll">
            <v-btn text :disabled="options.page <= 0" @click="prevPage"
              ><v-icon size="24">fas fa-chevron-left</v-icon></v-btn
            >
            <v-btn
              text
              :disabled="
                options.itemsLength <= 0 ||
                  pageStop + options.start - 1 >= options.itemsLength
              "
              @click="
                nextPage(options.itemsLength, pageStop + options.start - 1)
              "
              ><v-icon size="24">fas fa-chevron-right</v-icon></v-btn
            >
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiWeb from '../service/api-web'
import * as _ from 'lodash'
import FormField from '@/components/form/FormField'
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import FormDatePicker from '@/components/form/FormDatePicker'
import FormAutocomplete from '@/components/form/FormAutocomplete'
import { ERRORS_SHOW } from '@/store/actions/errors'
import jsPDF from 'jspdf'
import * as moment from 'moment'
import { isUuid } from 'uuidv4'

export default {
  name: 'DataTable',
  components: {
    FormField,
    FormButton,
    FormSelect,
    FormDatePicker,
    FormAutocomplete
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      default: () => [],
      required: false
    },
    sortBy: {
      type: String,
      default: '',
      required: false
    },
    sortDesc: {
      type: Boolean,
      default: false,
      required: false
    },
    showSelect: {
      type: Boolean,
      default: false,
      required: false
    },
    singleSelect: {
      type: Boolean,
      default: false,
      required: false
    },
    endpoint: {
      type: String,
      default: '',
      required: false
    },
    filters: {
      type: Array,
      default: () => [],
      required: false
    },
    noHideFilters: {
      type: Boolean,
      default: false,
      required: false
    },
    search: {
      type: Boolean,
      default: false,
      required: false
    },
    searchLabel: {
      type: String,
      default: 'Pesquisar',
      required: false
    },
    searchDefault: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: Array,
      default: () => [],
      required: false
    },
    hideFooter: {
      type: Boolean,
      default: false,
      required: false
    },
    autoSearch: {
      type: Boolean,
      default: true,
      required: false
    },
    additionalData: {
      type: Object,
      default: null,
      required: false
    },
    itemsPerPage: {
      type: Number,
      default: 10,
      required: false
    },
    page: {
      type: Number,
      default: 0,
      required: false
    },
    disableLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    pdf: {
      type: Boolean,
      default: false,
      required: false
    },
    csv: {
      type: Boolean,
      default: false,
      required: false
    },
    excel: {
      type: Boolean,
      default: false,
      required: false
    },
    limitPage: {
      type: Array,
      default: () => [5, 10, 15, 20, 30],
      required: false
    }
  },
  data: () => ({
    options: {
      itemsPerPage: 10,
      page: 0,
      sortBy: [],
      sortDesc: [],
      showSelect: false,
      start: 1,
      itemsLength: 0
    },
    selected: [],
    selectorPageOpen: false,
    rows: [],
    loading: true,
    mostraFiltroAvancado: false,
    filtrosAvancados: [],
    searchValue: '',
    searchChange: null,
    itemsSelected: [],
    valuesInput: {},
    moneyMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 2,
      masked: false
    }
  }),
  watch: {
    endpoint: {
      deep: true,
      handler() {
        this.options.page = 0
        this.reload()
      }
    },
    valuesInput: {
      deep: true,
      handler() {
        this.onSelect()
      }
    },
    filters: {
      deep: true,
      handler(oldval, newval) {
        if (oldval !== newval) {
          this.normalizeFilters()
        }
      }
    },
    data: {
      deep: true,
      handler() {
        this.loading = false
        this.rows = this.data || []
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    columns() {
      return (this.headers || []).map(item => item.value)
    },
    hasAdvancedFilter() {
      return this.filtrosAvancados.length > 0
    },
    getFilters() {
      return this.mostraFiltroAvancado || this.noHideFilters
        ? this.filtrosAvancados
        : []
    },
    allIsSelected() {
      if (
        (this.rows.filter(r => !r.disabled) || []).length ===
        (this.selected || []).length
      ) {
        return true
      } else {
        return false
      }
    },
    partialIsSelected() {
      if ((this.selected || []).length > 0) {
        return !this.allIsSelected
      }
      return false
    },
    tableOptions() {
      return {
        ...this.options,
        page: 0
      }
    }
  },
  created() {
    this.searchValue = this.searchDefault || ''
    this.normalizeFilters()
    this.options.itemsPerPage = this.itemsPerPage
    this.options.page = this.page
    this.options.start = (this.page * 1 || 0) * this.itemsPerPage + 1
    this.options.sortBy = [this.sortBy]
    this.options.sortDesc = [this.sortDesc]
    this.searchChange = _.debounce(() => {
      if (this.autoSearch) {
        this.options = {
          ...this.options,
          page: 0,
          start: 1
        }
        return this.loadData()
      }
      return null
    }, 2000)
    this.searchClick = _.debounce(() => {
      const obj = {
        filtrosAvancados: this.filtrosAvancados,
        searchValue: this.searchValue
      }
      this.$emit('set-value-filter', obj)
      this.options = {
        ...this.options,
        page: 0,
        start: 1
      }
      return this.loadData()
    }, 500)
    if ((this.data && this.data.length > 0) || this.disableLoading) {
      this.loading = false
      this.rows = this.data
    }
    if (Array.isArray(this.value)) {
      this.selected = this.value
    }
    this.loadData()
  },
  methods: {
    normalizeFilters() {
      this.filtrosAvancados = (this.filters || []).map(filter => ({
        label: _.get(filter, 'label', ''),
        query: _.get(filter, 'query', ''),
        value: _.get(filter, 'default', ''),
        options: _.get(filter, 'options', []),
        request: _.get(filter, 'request', {}),
        type: _.get(
          filter,
          'type',
          _.get(filter, 'options', []).length > 0 ? 'select' : 'text'
        ),
        cols: _.get(filter, 'cols', ''),
        clearable: _.get(filter, 'clearable', '') === 'false' ? false : true
      }))
    },
    onSelectAll() {
      if (!this.allIsSelected) {
        this.selected = []
        this.selected = this.rows.filter(r => !r.disabled) || []
      } else {
        this.selected = []
      }
      this.onSelect()
    },
    onSelect() {
      let values = Array.isArray(this.selected)
        ? this.selected
        : [this.selected]
      values = values.map(item => {
        return Object.keys(item).reduce((_values, key) => {
          return {
            ..._values,
            [key]: _.get(
              this.valuesInput,
              [item.$internal, key],
              _.get(item, key)
            )
          }
        }, {})
      })
      this.$emit('input', values)
      this.$emit('select', values)
    },
    clearSelect() {
      this.selected = []
      this.onSelect()
    },
    onClickRow(col, item) {
      let clickable = _.get(col, 'clickable', true)
      if (clickable && !this.loading) {
        this.$emit('click', item, col)
      }
    },
    selectPerPage(itemsPerPage) {
      this.$emit('set-pagination', itemsPerPage, 0)
      this.options = {
        ...this.options,
        itemsPerPage,
        page: 0,
        start: 1
      }
      this.clearSelect()
      this.loadData()
    },
    prevPage() {
      if (this.options.page > 0) {
        let newPage = this.options.page - 1
        this.options = {
          ...this.options,
          page: newPage,
          start: (newPage * 1 || 0) * this.options.itemsPerPage + 1
        }
        this.clearSelect()
        this.loadData()
        this.$emit('set-pagination', this.options.itemsPerPage, newPage)
      }
    },
    nextPage(itemsLength, pageStop) {
      if (itemsLength > 0 && pageStop < itemsLength) {
        let newPage = this.options.page + 1
        this.options = {
          ...this.options,
          page: newPage,
          start: (newPage * 1 || 0) * this.options.itemsPerPage + 1
        }
        this.clearSelect()
        this.loadData()
        this.$emit('set-pagination', this.options.itemsPerPage, newPage)
      }
    },
    reload() {
      this.clearSelect()
      this.loadData()
    },
    onSortBy(sortBy) {
      if (sortBy && sortBy.length > 0) {
        this.$emit('set-sort', sortBy[0])
        this.options.sortBy = sortBy
        this.clearSelect()
        this.loadData()
      }
    },
    onSortDesc(sortDesc) {
      if (sortDesc && sortDesc.length > 0) {
        this.$emit('set-sort-desc', sortDesc[0])
        this.options.sortDesc = sortDesc
        this.clearSelect()
        this.loadData()
      } else {
        this.options.sortDesc = []
      }
    },
    customSort(items) {
      return items
    },
    loadData() {
      let orderIndex = 0
      if (!this.endpoint) {
        return
      }
      if (this.options.sortBy && this.options.sortBy.length > 0) {
        this.headers.find((item, index) => {
          if (item.value === _.get(this.options, 'sortBy[0]', '')) {
            orderIndex = index
            return item
          }
        })
      }

      let tableParams = {
        columns: (this.headers || []).map(col => ({
          data: col.value,
          searchable: true,
          orderable: true
        })),
        order: [
          {
            columnName: _.get(this.options, 'sortBy[0]', ''),
            columnType:
              _.get(
                this.headers.filter(
                  filtro =>
                    _.get(filtro, 'value', '') ===
                    _.get(this.options, 'sortBy[0]', '')
                ),
                '[0].type',
                ''
              ) === 'string'
                ? true
                : false,
            column: orderIndex,
            dir: _.get(this.options, 'sortDesc.[0]', false) ? 'desc' : 'asc'
          }
        ],
        start: this.options.start - 1,
        length: this.options.itemsPerPage,
        search: {
          value: this.searchValue || '',
          regex: false
        },
        additionalData: this.additionalData || null
      }

      this.loading = true
      let queryParams = this.filtrosAvancados.reduce((queries, query) => {
        return {
          ...queries,
          [query.query]: query.value
        }
      }, {})
      ApiWeb.api
        .post(this.endpoint, tableParams, {
          params: { ...queryParams, search: this.searchValue || '' }
        })
        .then(resp => {
          this.rows = _.get(resp, 'data.data', []).map((row, index) => ({
            ...row,
            $internal: `row_${index}_${Math.random() * 1000}`
          }))
          this.loadInput()
          this.loading = false
          this.options = {
            ...this.options,
            itemsLength: _.get(resp, 'data.recordsTotal', 0),
            start: (this.options.page * 1 || 0) * this.options.itemsPerPage + 1
          }
          this.selected = []
        })
        .catch(err => {
          this.loading = false
          this.$store.dispatch(ERRORS_SHOW, err)
        })
    },
    loadInput() {
      this.valuesInput = (this.rows || []).reduce((_values, row) => {
        const rowInputs = this.headers.reduce((inputs, header) => {
          if (header.editable) {
            const key = _.get(header, 'value')
            _.set(inputs, key, _.get(row, key))
          }
          if (header.checkDisabled) {
            const disabled = _.get(row, 'disabled', false)
            _.set(inputs, 'disabled', disabled)
          }

          return inputs
        }, {})
        return {
          ..._values,
          [_.get(row, '$internal')]: rowInputs
        }
      }, {})
    },
    toggleAdvancedFilter() {
      this.mostraFiltroAvancado = !this.mostraFiltroAvancado
      this.filtrosAvancados = this.filtrosAvancados.map(filter => ({
        ...filter,
        value: ''
      }))
    },
    calculePageStart(pageStart) {
      if (this.options.itemsLength > 0) {
        return this.options.start + pageStart
      }
      return pageStart
    },
    onSearchFilter(search, filter) {
      if (filter.request && filter.request.endpoint) {
        const {
          endpoint,
          query = 'search',
          value = 'value',
          text = 'text'
        } = filter.request
        ApiWeb.api(`${endpoint}?${query}=${search}`)
          .then(resp => {
            filter.options = _.get(resp, 'data', []).map(item => ({
              value: _.get(item, value, 'value'),
              text: _.get(item, text, 'text')
            }))
          })
          .catch(err => {
            filter.options = []
            this.$store.dispatch(ERRORS_SHOW, err)
          })
      }
    },
    validateValue(value) {
      if (typeof value === 'number' && value == 0) {
        return '0,00'
      }
      return value || ''
    },
    exportPDF() {
      const doc = new jsPDF('p', 'mm', 'a4')
      doc.setFontSize(16)
      doc.text('LifeApps Omnichannel', 10, 10)
      const data = this.rows.map(item => {
        const newItem = {}
        this.headers.forEach(header => {
          let value = item[header.value]
          if (header.type && header.type == 'datetime') {
            moment.locale('pt-br')
            var a = moment(value * 1000)
            value = a.format('L HH:mm:ss')
          } else if (typeof value === 'number') {
            value = value.toString()
          } else if (typeof value === 'boolean') {
            value = value ? 'Sim' : 'Não'
          } else if (isUuid(value)) {
            value = '-'
          } else if (!value) {
            value = '-'
          }
          newItem[header.text] = value
        })
        return newItem
      })
      const header = this.headers.map(h => h.text)
      const config = {
        autoSize: true,
        fontSize: 8,
        headerBackgroundColor: '#12558C',
        headerTextColor: '#FFFFFF'
      }
      doc.table(5, 15, data, header, config)
      doc.save('LifeApps Omnichannel.pdf')
    },
    exportCSV() {
      let csvContent = 'data:text/csv;charset=utf-8,'
      const csvHeaders = this.headers.map(header => header.text)
      csvContent += csvHeaders.join(';') + '\n'
      this.rows.forEach(item => {
        let row = []
        this.headers.forEach(header => {
          let value = item[header.value]
          if (header.type && header.type == 'datetime') {
            moment.locale('pt-br')
            var a = moment(value * 1000)
            value = a.format('L HH:mm:ss')
          } else if (typeof value === 'number') {
            value = value.toString()
          } else if (typeof value === 'boolean') {
            value = value ? 'Sim' : 'Não'
          }
          row.push(value)
        })
        csvContent += row.join(';') + '\n'
      })
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'LifeApps_Omnichannel.csv')
      document.body.appendChild(link)
      link.click()
    },
    exportExcel() {
      const XLSX = require('xlsx')
      const wb = XLSX.utils.book_new()
      const wsData = this.rows.map(item => {
        const newItem = {}
        this.headers.forEach(header => {
          let value = item[header.value]
          if (header.type && header.type == 'datetime') {
            moment.locale('pt-br')
            var a = moment(value * 1000)
            value = a.format('L HH:mm:ss')
          } else if (typeof value === 'number') {
            value = value.toString()
          } else if (typeof value === 'boolean') {
            value = value ? 'Sim' : 'Não'
          }
          newItem[header.text] = value
        })
        return newItem
      })
      const ws = XLSX.utils.json_to_sheet(wsData)
      XLSX.utils.book_append_sheet(wb, ws, 'Planilha 1')
      XLSX.writeFile(wb, 'LifeApps_Omnichannel.xlsx')
    },
    fullDownloadCsv(csvEndpoint) {
      let queryParams = this.filtrosAvancados.reduce((queries, query) => {
        return {
          ...queries,
          [query.query]: query.value
        }
      }, {})
      let params = Object.keys(queryParams)
        .map(
          key =>
            encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
        )
        .join('&')
      window.open(`${csvEndpoint}&${params}`, '_blank')
    }
  }
}
</script>

<style lang="scss">
.data-table {
  width: 100%;
  .data-table-filter {
    padding-bottom: 15px;
  }

  .custom-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 20px;
    .paga-index {
      padding: 0 15px;
    }
    .perpage-selector {
      min-width: 50px;
      display: flex;
      align-items: center;
      flex: 0 0 0;
      justify-content: flex-end;
      white-space: nowrap;
      .icon-selector {
        transition: all 0.3s ease-in-out;
      }
      .open {
        .icon-selector {
          transform: rotate(180deg);
        }
      }
    }
    .pagination-controll {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 50px;
        padding: 5px;
        min-width: initial;
        height: initial;
      }
      button:last-child {
        margin-left: 5px;
      }
    }
  }
  @media (max-width: 768px) {
    .custom-footer {
      flex-direction: column;
      .paga-index,
      .pagination-controll {
        padding: 7px 15px;
      }
    }
  }
  .line-disabled {
    opacity: 0.5;
    &:hover {
      background: #fff !important;
    }
  }
  .form-editable {
    .v-text-field__details {
      display: none !important;
    }
  }
}
.clickable {
  cursor: pointer;
}
</style>
