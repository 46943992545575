<template lang="html">
  <div style="text-align: left;">
    <a href="https://bit.ly/3TdQYyM" target="_blank">
      <img v-bind:src="urlImagem" class="banner" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'MarketingModules',
  data: () => ({
    urlImagem: '/images/BANNER_MODULOS_INTERNO.png'
  })
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 100%;
  border-radius: 2em 2em 2em 2em / 2em 2em 2em 2em;
}
</style>
